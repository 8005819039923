import React from "react"
import useTranslations from "../../utils/useTranslations"
import LocalizedLink from "../../components/localizedLink"
import adFreeButton from "../../images/prodnews/bt_adfree_button.png"
import googlePlayButton from "../../images/prodnews/google-play.png"
import nordTowerAd from "../../images/prodnews/nord_tower_300_600.png"
import ChimpForm from "../../components/chimpForm"
import { Link } from "gatsby"
import { getNordVpnAdLink } from "src/utils/getNordVpnAdLink";

const nordVpnLink = getNordVpnAdLink();

class ProdNews extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			isMac:false
		}
	}

	componentDidMount(){
		if(window.gatsbyIsMac){
			this.setState({
				isMac:true
			})
		}
	}

	render() {
		const { text } = this.props.pageContext

		const t = useTranslations(text)

		var winDisplay = style.show
		var macDisplay = style.hide
		if(this.state.isMac){
			winDisplay = style.hide
			macDisplay = style.show
		}

		let locale = this.props.pageContext.locale
		let catLink = (locale) ? `url(/images/pro-free-trial/cat-14days-${locale}.png)` : `url(/images/pro-free-trial/cat-14days-en.png)`

		let adBg = {
			background: catLink,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
		}
		
		return (
			<div className="prodnews-container w-100 d-flex ">
				<div className="container align-self-center prodnews-inner-container my-5">
					
					<div className="row text-center text-white flex-lg-nowrap justify-content-center">
						<div className="col-9">
							<div className="row">
								<div className="col-12">
									<p style={font.lg} className="font-weight-bold mb-3">{t(`You've just been upgraded to the latest version of BitTorrent!`)}</p>
									<p className="mb-1">{t(`Every few weeks, we release a new version with improvements`)}</p>
									<p className="mb-0">Read the blog post for what's <Link style={winDisplay} className="text-primary font-weight-bold" to="/blog/releases/windows/" id="prodnews-releasenotes-win">
											new in this version.
										</Link>
										<Link style={macDisplay} className="text-primary font-weight-bold" to="/blog/releases/mac/" id="prodnews-releasenotes-mac">
											new in this version.
										</Link>
									</p>
								</div>
							</div>
							<div className="row my-5 justify-content-center">
								{this.state.isMac &&
									<div className="offset-1 col-10 bg-dark p-5 rounded">
										<p className="text-white" style={font.md}>{t(`Get BitTorrent news update and special offers!`)}</p>

											<ChimpForm
											  id="prodnews-mailchimp"
											  list="https://bittorrent.us5.list-manage.com/subscribe/post?u=a3baea4e54ff8e8b235488c11&amp;id=159eb566c6"
											  classButton="prodnews-button"
											/>

										<p className="text-white" >By signing up, you agree to our <Link to="/legal/privacy-policy" id="prodnews-privacylink" className="text-primary font-weight-bold">Privacy Policy</Link></p>
									</div>
								}
								{!this.state.isMac &&
									<a id="prodnews-pro-free-trial" target="_blank" href="https://store.bittorrent.com/849/purl-btprotrialweb14" style={{...adStyle.a, ...adBg}}>
						      		</a>
								}
							</div>
							<div className="row">
								<div className="col-8 offset-2">
									<div className="row">
										<div className="col-12 col-md-6 d-inline-block">
											<a id="prodnews-go-adfree-img" href="https://store.bittorrent.com/849/purl-btafwebw?x-source=emailcapturewithad" target="_blank" rel="noopener noreferrer">
												<img src={adFreeButton} alt="ad free button"/>
											</a>
											<p className="mt-3 mb-0">{t(`Try BitTorrent without ads!`)}</p>
											<a id="prodnews-go-adfree-textlink" className="text-primary font-weight-bold" href="https://store.bittorrent.com/849/purl-btafwebw?x-source=emailcapturewithad" target="_blank" rel="noopener noreferrer">{t(`Go ad-free`)}</a>
										</div>
										<div className="col-12 col-md-6 d-inline-block">
											<a id="get-android-img" href="https://play.google.com/store/apps/details?id=com.bittorrent.client&referrer=utm_source%3Dclient%26utm_medium%3Dweb%26utm_term%3Dprodnews%26utm_campaign%3Dprodnews" target="_blank" rel="noopener noreferrer">
												<img src={googlePlayButton} alt="Google Play button for BitTorrent Android"/>
											</a>
											<p className="mt-3 mb-0">{t(`Get the Android mobile app.`)}</p>
											<a id="get-android-textlink" className="text-primary font-weight-bold" href="https://play.google.com/store/apps/details?id=com.bittorrent.client&referrer=utm_source%3Dclient%26utm_medium%3Dweb%26utm_term%3Dprodnews%26utm_campaign%3Dprodnews" rel="noopener noreferrer">On Google Play</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-3 d-flex align-items-center justify-content-center">
							<a id="prodnews-nord-tower-ad" href={nordVpnLink} target="_blank">
								<img className="img-fluid" src={nordTowerAd} alt="NordVPN - Get 66% off the 2-year plan"/>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProdNews

const adStyle = {
	a: {
		display: 'block',
		height: '349px',
		width: '420px'
	}
}
const font = {
	lg:{
		fontSize:'26px'
	},
	md:{
		fontSize:'20px'
	}
}

const style = {
	show:{
		display:'inline-block'
	},
	hide:{
		display:'none'
	}
}